import React, { useState, useEffect } from 'react';
import {Layout} from "@douyinfe/semi-ui";

const Modelinfo = () => {
  // 使用useState钩子来控制是否显示iframe
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    // 设置为true以显示iframe
    setShowIframe(true);
  }, []);

  return (
    <Layout>
      <Layout.Header>
        <h3>模型信息</h3> {/* 标题内容 */}
      </Layout.Header>
      <Layout.Content>
        {showIframe && (
          <iframe
            src="https://qaij6fwlict.feishu.cn/share/base/view/shrcneWN568MfYz7kngvzE4pWff" // 替换为需要显示的URL
            // src="https://rank.opencompass.org.cn/home"
            title="External Content"
            style={{ width: '100%', height: '100vh', border: 'none' }}
          />
        )}
      </Layout.Content>
    </Layout>
  );
};

export default Modelinfo;
