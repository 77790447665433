// BarChart.js
import React, { useEffect, useRef, useState } from 'react';
import { Layout, Spin, Table, Card, Row, Col } from '@douyinfe/semi-ui';
import VChart from '@visactor/vchart';
import { useNavigate } from 'react-router-dom';

const BarChart = () => {
    const [loading, setLoading] = useState(false);
    const chartRef = useRef(null);
    const navigate = useNavigate();

    const data = [
        { Model: 'GPT-4o-20240513', MMLU: 65.0 },
        { Model: 'GPT-4-Turbo-20240409', MMLU: 62.8 },
        { Model: 'Doubao-pro-4k-240515', MMLU: 62.5 },
        { Model: 'Qwen-Max-0428', MMLU: 60.2 },
        { Model: 'Claude3-Opus', MMLU: 59.0 },
        { Model: 'Yi-Large', MMLU: 58.9 },
        { Model: 'GLM-4', MMLU: 48.8 },
        { Model: 'DeepSeek-V2-Chat', MMLU: 47.9 },
        { Model: 'InternVL2-40B', MMLU: 69.7 }
    ];

    // Sort data in descending order by MMLU score
    const sortedData = data.sort((a, b) => b.MMLU - a.MMLU);

    const spec = {
        type: 'bar',
        data: [
            {
                id: 'barData',
                values: sortedData
            }
        ],
        xField: 'Model',
        yField: 'MMLU',
        legends: {
            visible: false
        },
        title: {
            visible: true,
            text: 'MMLU Scores of Large Language Models',
        },
        bar: {
            state: {
                hover: {
                    stroke: '#000',
                    lineWidth: 1
                }
            }
        },
        tooltip: {
            mark: {
                content: [
                    {
                        key: datum => datum['Model'],
                        value: datum => datum['MMLU']
                    }
                ]
            }
        }
    };

    useEffect(() => {
        if (!chartRef.current) {
            const chart = new VChart(spec, { dom: 'chart-container' });
            chart.on('bar:click', (params) => {
                const model = params.data.Model;
                navigate(`/model/${model}`);
            });
            chart.renderAsync();
            chartRef.current = chart;
        }
    }, []);

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
            title: 'Model',
            dataIndex: 'Model',
            key: 'Model',
        },
        {
            title: 'MMLU Score',
            dataIndex: 'MMLU',
            key: 'MMLU',
        }
    ];

    const rankedData = sortedData
        .map((item, index) => ({ ...item, rank: index + 1 }));

    return (
        <Layout>
            <Layout.Header>
                <h3>CompassRank 评测榜单</h3>
            </Layout.Header>
            <Layout.Content>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card title="大语言模型月度榜单">
                            <Spin spinning={loading}>
                                <div style={{ height: 500 }}>
                                    <div id="chart-container" style={{ width: '100%', minWidth: 100 }}></div>
                                </div>
                            </Spin>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="排行榜">
                            <Spin spinning={loading}>
                                <Table columns={columns} dataSource={rankedData} pagination={false} />
                            </Spin>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default BarChart;
